import Component from "../component";
import Swiper, { Navigation, Mousewheel } from "swiper";

export default class Slider extends Component {
  constructor(...args) {
    super(...args);

    this._retrieveDOM();
  }

  _retrieveDOM() {
    this.dom.slide = this.dom.component.querySelectorAll(".swiper-slide");
    this.dom.slider = this.dom.component.querySelector(".slider");
  }

  init() {
    // SLIDER IMAGE
    if (this.dom.slider.dataset.slidesPerView === "auto") {
      this.Slider = new Swiper(this.dom.slider, {
        modules: [Navigation, Mousewheel],
        direction: "horizontal",
        slidesPerView: "auto",
        spaceBetween: 16,
        breakpoints: {
          769: {
            spaceBetween: 24
          }
        },
        navigation: {
          nextEl: this.dom.component.querySelector(".navigation-next"),
          prevEl: this.dom.component.querySelector(".navigation-prev")
        }
      });
    } else {
      const slidesConfig = this.dom.slider.dataset.slidesPerView.split(" ").reduce((config, item) => {
        const [breakpoint, slides] = item.split(":");
        config[breakpoint] = {
          slidesPerView: parseInt(slides),
          spaceBetween: 24
        };
        return config;
      }, {});

      this.Slider = new Swiper(this.dom.slider, {
        modules: [Navigation, Mousewheel],
        direction: "horizontal",
        slidesPerView: 1, // Mobile default
        spaceBetween: 16,
        breakpoints: slidesConfig,
        navigation: {
          nextEl: this.dom.component.querySelector(".navigation-next"),
          prevEl: this.dom.component.querySelector(".navigation-prev")
        }
      });
    }
  }
}
